<template>
  <div class="sub_add" v-if="isShow" v-wechat-title="this.title">
    <div class="top">
      <Steps :dataSource="list" :active="2" :class="short_name === 'xrb' ? 'steps last' : 'steps'"></Steps>
      <!-- <div class="remark">请您按照步骤提交申请所需要的信息～</div> -->
    </div>
    <div class="ocr">
      <!-- <div class="uploadTitle">上传证件照片</div> -->
      <div class="uploadTitle">{{ attach_title ? attach_title : '上传照片' }}</div>
      <div class="uploadStandard">至少上传1张，单张文件不超过5MB</div>
      <div class="ocrBox" v-if="formList.length > 0">
        <div style="flex: 1" v-for="(item, index) in formList[formIndex].form" :key="index">
          <!-- <div v-if="item.readonly==1" class="upload-img" :key="index">
                        <div class="upload-img-a" :style="{backgroundImage: 'url('+(uploadPhoto?uploadPhoto:idcard_front)+')'}"></div>
                    </div>
                    <div v-else class="upload-img" :key="index" @click="chooseImage()">
                        <div class="upload-img-a" :style="{backgroundImage: 'url('+(uploadPhoto?uploadPhoto:idcard_front)+')'}"></div>
                    </div> -->
          <template v-if="item.readonly != 1">
            <div v-if="isWeixin" class="upload-img" @click="chooseImage()">
              <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
            </div>
            <ImageUploader v-else class="upload-img" v-model="images" @change="">
              <template slot="content">
                <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
              </template>
            </ImageUploader>
          </template>
          <div class="picBox">
            <div class="prelist" v-for="(itm, idx) in images" :key="idx">
              <img :src="itm" class="img" @click="show(idx)" />
              <img src="/static/clear.png" class="clear" @click="delImg(idx)" v-if="item.readonly != 1" />
            </div>
          </div>
        </div>
        <!-- <div class="upload-img" @click="chooseImage('front')">
                    <div class="upload-img-b" :style="{backgroundImage: 'url('+idcard_front+')'}"></div>
                </div>
                <div class="upload-img" @click="chooseImage('back')">
                    <div class="upload-img-b" :style="{backgroundImage: 'url('+idcard_back+')'}"></div>
                </div> -->
      </div>
      <div class="uploadMark" v-html="attach_desc">
        <!-- 证件必须是医师资格证，医生职业注册信息查询网站截图，医院官网截图。可以是扫描件或是数码拍摄照片。仅支持jpg，png，jpeg的图片格式。 -->
        <!-- {{attach_desc}} -->
      </div>
      <div class="standard">上传标准</div>
      <div class="standardImg">
        <img src="/static/doctorUpload.png" />
      </div>
      <div class="formBox">
        <van-form class="from">
          <template v-for="(itm, idx) in formInput">
            <Configure :item="itm" v-if="itm.type == 'configure'" :relation="true"></Configure>
            <Content :item="itm" v-else :relation="true"></Content>
          </template>
        </van-form>
      </div>
    </div>
    <div class="btn">
      <van-button round type="info" size="large" class="up" :disabled="disabled" @click="next">下一步</van-button>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog, ImagePreview } from 'vant'
import { Ocr } from '@/components/ocr.js'
import ImageUploader from '@/components/ImageUploader'
import {
  projectDoctor,
  getDoctorForm,
  getSubProjectAgreement,
  getcertificationOcr,
  subCertification,
  StepThree //第三步提交资质图
} from '@/api/api'
import Steps from '@/components/steps.vue'
import formPanel from '@/components/form.vue'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import Configure from '../add/configure.vue'
import Content from '../add/content.vue'
export default {
  name: '',
  components: {
    Steps,
    formPanel,
    Configure,
    Content,
    ImageUploader
  },
  mixins: [Ocr],
  data() {
    return {
      title: ' ',
      isShow: false,
      idcard_front: '/static/doctor.png',
      idcard_back: '/static/doctor.png',
      uploadPhoto: '',
      images: [],
      project_doctor_id: null,
      showAddress: false,
      showHospital: false,
      disabled: false,
      address: '',
      hospital: '',
      addressList: [],
      hospitalList: [],
      list: [
        {
          step_name: '身份信息'
        },
        {
          step_name: '职称信息'
        },
        {
          step_name: '上传证件'
        },
        {
          step_name: '上传身份证'
        },
        {
          step_name: '签署协议'
        }
      ],
      formList: [],
      formIndex: 2,
      attach_title: '',
      attach_desc: '',
      dire: 'front', // 前进:front;后退:back
      short_name: ''
    }
  },
  mounted() {
    this.dire = this.$route.query.dire
    this.project_doctor_id = this.$route.query.project_doctor_id
    this.short_name = this.basicInformation.projects[0].short_name
    if (['xrb', 'sshy'].includes(this.short_name)) {
      this.list[4].step_name = `阅读知情同意书`
    }
    this.init()
  },
  computed: {
    ...mapGetters(['sub_id']),
    ...mapState(['imgList', 'basicInformation']),
    formInput() {
      if (this.formList[this.formIndex]) {
        console.log(this.formList[this.formIndex].input_form, 'input_from')
        return this.formList[this.formIndex].input_form
      }
    }
  },
  methods: {
    ...mapActions(['SET_IMG_LIST', 'SET_CERTIFICATION_DATA']),
    getRelationShow(itm) {
      let allList = []
      for (let i in this.list) {
        let moduleList = this.list[i].module
        for (let j in moduleList) {
          let inputList = moduleList[j].input
          for (let k in inputList) {
            allList.push(inputList[k])
          }
        }
      }
      if (itm.asso && itm.asso.length > 0) {
        let isshow = false
        for (let i in itm.asso) {
          for (let j in allList) {
            if (itm.asso[i].name == allList[j].name) {
              if (itm.asso[i].type == 'daterange') {
                if (itm.asso[i].value == allList[j].value.day) {
                  isshow = true
                } else {
                  itm.isShow = false
                  isshow = false
                  return false
                }
              } else if (itm.asso[i].type == 'checkbox') {
                if (allList[j].value.indexOf(itm.asso[i].value) != -1) {
                  isshow = true
                } else {
                  itm.isShow = false
                  isshow = false
                  return false
                }
              } else {
                if (itm.asso[i].value == allList[j].value) {
                  isshow = true
                } else {
                  itm.isShow = false
                  isshow = false
                  return false
                }
              }
            }
          }
        }
        if (isshow) {
          itm.isShow = true
          return true
        } else {
          itm.isShow = false
          return false
        }
      } else {
        itm.isShow = true
        return true
      }
    },
    async init() {
      // if (this.imgList.length > 0) {
      //     this.idcard_front = this.imgList[0]
      //     this.idcard_back = this.imgList[1]
      // }
      let pdata = await getSubProjectAgreement()
      this.attach_title = pdata.items[0].attach_title
      this.attach_desc = pdata.items[0].attach_desc
      let res = await projectDoctor()
      // if (res.doctor_certificate.length > 0) {
      //     this.idcard_front = res.doctor_certificate[0]
      //     this.idcard_back = res.doctor_certificate[1]
      // }
      const data = await getDoctorForm({
        params: {
          doctor_id: res.doctor.id,
          subproject_id: this.sub_id
        }
      })
      this.formList = data
      for (let i in this.formList) {
        let ele = this.formList[i]
        if (ele.form.length > 0) {
          for (let j in ele.form) {
            let e = ele.form[j]
            if (!e.value) {
              e.readonly = 0
            }
          }
        }
      }
      if (this.formList[this.formIndex].form.length < 1) {
        if (this.dire == 'front') this.next()
        if (this.dire == 'back') this.back()
      } else {
        for (let i in this.formList) {
          if (this.formList[i].form.length < 1) {
            this.list.splice(i, 1)
          }
        }
        let arr = this.formList[this.formIndex].form[0].value
        if (this.imgList.length > 0) {
          this.images = this.imgList
        } else if (arr.length > 0) {
          this.images = arr
        }
        // 判断初始化时是否需要ocr
        // let count  = 0;
        // for(let item of this.formInput) {
        //     if(item.value == "") count += 1
        // }
        // if(count == 4) this.ocr();

        this.title = '上传证件'
        this.isShow = true
      }
    },
    next() {
      // if ((!this.idcard_front && !this.idcard_back) || (this.idcard_front == '/static/doctor.png' && this
      //         .idcard_back == '/static/doctor.png')) {
      //     Toast('请上传证件照片')
      //     return false
      // }
      let data = []
      // if (this.idcard_front && this.idcard_front != '/static/doctor.png') {
      //     data.push(this.idcard_front)
      // }
      // if (this.idcard_back && this.idcard_back != '/static/doctor.png') {
      //     data.push(this.idcard_back)
      // }
      for (let i in this.formList[this.formIndex].form) {
        let ele = this.formList[this.formIndex].form[i]
        if (this.images.length < 1 && ele.require) {
          Toast('请上传证件照片')
          return false
        } else {
          data = [...data, ...this.images]
        }
      }

      // 判断是否跳转到下一页
      let map = new Map()
      for (let item of this.formInput) {
        if (item.require == 1 && !item.value) {
          let toast = '必须填写' + item.label
          Toast(toast)
          this.toNext = false
          this.$nextTick(() => {
            this.toNext = true
          })
          return
        }
        map.set(item.name, item.value)
      }
      let certificationData = {
        sign_department: map.get('sign_department'),
        sign_user: map.get('sign_user'),
        certification_code: map.get('certification_code'),
        certification_date: map.get('certification_date')
      }
      console.log('next data', data)
      this.SET_IMG_LIST(data)
      this.SET_CERTIFICATION_DATA(certificationData)
      const self = this
      // 存资质认证图
      StepThree({
        project_doctor_id: this.project_doctor_id,
        doctor_certificate: this.images
      })
        .then(res => {
          // sessionStorage.removeItem('informed_consent_sign')
          self.btnDisabled = false
          Toast.clear()
          if (this.formList[3].form.length > 0) {
            //若配置了上传身份证
            this.$router.replace({
              path: '/add_idcard',
              query: {
                project_doctor_id: this.project_doctor_id,
                dire: 'front'
              }
            })
          } else {
            //若没配置上传身份证
            this.$router.replace({
              path: '/add_agreement',
              query: {
                project_doctor_id: this.project_doctor_id,
                dire: 'front'
              }
            })
          }
        })
        .catch(err => {
          self.btnDisabled = false
          Toast.clear()
          Toast(err.response.data.message)
        })
    },
    back() {
      this.$router.replace({
        path: '/add_position',
        query: {
          project_doctor_id: this.project_doctor_id,
          dire: 'back'
        }
      })
    },
    show(index) {
      let idx = index
      let images = this.images
      this.$nextTick(() => {
        ImagePreview({
          images,
          startPosition: idx
        })
      })
    },
    delImg(index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          this.images.splice(index, 1)
          // if(this.images.length > 0) {
          //     this.ocr();
          // }else {
          //     for (let ite of this.formInput) {
          //         ite.value = ""
          //     }
          // }
        })
        .catch(err => {
          console.log(err)
        })
    }
    // async ocr() {
    //     try {
    //         await getcertificationOcr({
    //             images:this.images,
    //             project_doctor_id: this.project_doctor_id,
    //         }).then(res => {
    //             for(let item in res) {
    //                 for (let ite of this.formInput) {
    //                     if(item == ite.name) {
    //                         ite.value = res[item]
    //                     }
    //                 }
    //             }
    //         })
    //     } catch (err) {
    //         Toast(err.response.data.message)
    //     }

    // }
  }
}
</script>

<style lang="scss" scoped>
.sub_add {
  height: 100%;
  width: 100%;

  .top {
    width: 100%;
    height: 97px;
    background-image: linear-gradient(to right, #5b9bf0, #3d87ea);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;

    .steps {
      width: 100%;
      margin-bottom: 17px;
    }

    .remark {
      width: 243px;
      height: 21px;
      background: #006ae1;
      border-radius: 10px;
      font-size: 11px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
    }
  }

  .ocr {
    height: 100%;
    width: 100%;
    padding: 9px 11px 0 9px;
    box-sizing: border-box;
    text-align: left;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;

    .standard,
    .identity,
    .uploadTitle {
      font-size: 15px;
      color: #232323;
      margin-bottom: 13px;
    }

    .uploadStandard,
    .uploadMark {
      color: #a8a8a8;
      font-size: 12px;
      margin: 0 20px 3px;
    }

    .ocrBox {
      // height: 128px;
      width: 70%;
      margin: auto;
      display: flex;

      .upload-img {
        // height: 94px;
        // width: 139px;
        flex: 1;
        background-position: left top, right top, right bottom, left bottom;
        background-repeat: no-repeat;
        background-size: 16px;
        padding: 20px;

        .upload-img-a,
        .upload-img-b {
          width: 100%;
          height: 0;
          padding-top: 63%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: #fff;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
          border-radius: 5px;
        }
      }
    }

    .uploadMark {
      margin-bottom: 26px;
    }

    .standardImg {
      width: 100%;
      height: 90px;
      padding: 0 25px;
      box-sizing: border-box;
      margin-bottom: 12px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .picBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 5px;
    .prelist {
      height: 50px;
      width: 50px;
      margin-right: 10px;
      margin-bottom: 5px;
      position: relative;

      .img {
        height: 100%;
        width: 100%;
      }

      .clear {
        position: absolute;
        right: -2px;
        top: -2px;
        height: 13px;
        width: 13px;
      }
    }
  }
  .formBox {
    width: 100%;
  }
}
</style>
